<div class="left-sidebar" [ngClass]="{ mini: menu.isMini }">
  <div class="logo-cnt">
    <div class="logo-cnt-inner"></div>
  </div>

  <div class="left-menu-cnt">
    <perfect-scrollbar>


      <!-- Admin Menu start -->
      <ul>
        
        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/dashboard']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'Dashboard' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-tachometer"></i><span>Dashboard</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/industry']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'industry' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-industry"></i><span>Industry</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/job-Title']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'jobTitle' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-briefcase"></i><span>Job Title</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/job-Type']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'jobType' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-id-card-o"></i><span>Job Type</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/Job']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'jobs' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-wrench"></i><span>Jobs</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/User']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'user' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-users"></i><span>User</span>
          </div>
        </li>

        <li>
          <div
            class="inner-li"
            [routerLink]="['/main/Setting']"
            routerLinkActive="active"
            [tooltip]="menu.isMini ? 'settings' : ''"
            placement="right"
            container="body"
          >
          <i class="fa fa-cog"></i><span>Settings</span>
          </div>
        </li>

      </ul>
      <!-- Admin Menu end -->
    </perfect-scrollbar>
  </div>
</div>
