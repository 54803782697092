import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_guards';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';

const routes: Routes = [
	{
		path: 'main',
		canActivate: [ AuthGuard ],
		component: AppLayoutComponent,

		children: [
			{ path: '', redirectTo: 'dashboard', pathMatch: 'full' },
			{ path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
			
			{
				path: 'users',
				loadChildren: () => import('./users/users.module').then(m =>m.UsersModule),
				data: { breadcrumb: 'User', permission: 'list_category' }
			},
			{
				path: 'industry',
				loadChildren: () => import('./industry/industry.module').then(m =>m.IndustryModule),
				data: { breadcrumb: 'Industry' }
			},
			{
				path: 'job-Title',
				loadChildren: () => import('./jobTitle/jobTitle.module').then(m =>m.JobTitleModule),
				data: { breadcrumb: 'Job_Title' }
			},
			{
				path: 'job-Type',
				loadChildren: () => import('./jobType/jobType.module').then(m =>m.JobTypeModule),
				data: { breadcrumb: 'Job_Type' }
			},
			{
				path: 'User',
				loadChildren: () => import('./user/user.module').then(m =>m.UserModule),
				data: { breadcrumb: 'User' }
			},
			{
				path: 'Job',
				loadChildren: () => import('./job/job.module').then(m =>m.JobModule),
				data: { breadcrumb: 'Job' }
			},
			{
				path: 'Setting',
				loadChildren: () => import('./settings/settings.module').then(m =>m.SettingModule),
				data: { breadcrumb: 'Setting' }
			},
		]
	},
	{
		path: '',
		component: SiteLayoutComponent,
		children: [ { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) } ]
	},
	{
		path: 'home',
		component: SiteLayoutComponent,
		children: [ { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) } ]
	},
	{
		path: '**',
		loadChildren: () => import('./page-not-found/page-not-found.module').then(m => m.PageNotFoundModule)
	}

	//{ path: 'login', loadChildren: './login/login.module#LoginModule' }
];

@NgModule({
	imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
	exports: [ RouterModule ]
})
export class AppRoutingModule {}
