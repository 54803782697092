export class User {
	id?: string;
	// username: string;
	//password: string;
	// firstName: string;
	// lastName: string;
	email?: string;
	// mobile?: string;
	token?: string;
	type?: string;
	// logo?: string;
}
